<template>
  <div>
    <el-card shadow="never" class="aui-card--fill">
      <div class="mod-sys__menu">
        <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
          <el-form-item>
            <el-button type="primary" @click="addOrUpdateHandle()" size="small">新增</el-button>
          </el-form-item>
        </el-form>
        <el-table v-loading="dataListLoading" :data="dataList" border style="width: 100%;">
          <table-tree-column prop="name" label="名称" header-align="center" width="150"></table-tree-column>
          <el-table-column prop="icon" label="图标" header-align="center" align="center">
            <template slot-scope="scope">
              <svg class="icon-svg" aria-hidden="true"><use :xlink:href="`#${scope.row.icon}`"></use></svg>
            </template>
          </el-table-column>
          <el-table-column prop="type" label="类型" header-align="center" align="center">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.type === 0" size="small">菜单</el-tag>
              <el-tag v-else size="small" type="info">按钮</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="sort" label="排序" header-align="center" align="center"></el-table-column>
          <el-table-column prop="url" label="路由" header-align="center" align="center" width="150" :show-overflow-tooltip="true" ></el-table-column>
          <el-table-column prop="permissions" label="授权标识" header-align="center" align="center" width="150" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column label="操作" fixed="right" header-align="center" align="center" width="150">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row.id)">修改</el-button>
              <el-button type="text" size="small" @click="deleteHandle(scope.row.id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 弹窗, 新增 / 修改 -->
        <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
      </div>
    </el-card>
  </div>
</template>

<script>
import TableTreeColumn from '@/components/table-tree-column'
import AddOrUpdate from './menu-add-or-update'
export default {
  data () {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/erpMenu/list',
        deleteURL: '/erpMenu'
      },
      dataForm: {},               // 查询条件
      dataList: [],               // 数据列表
      dataListLoading: false,     // 数据列表，loading状态
      addOrUpdateVisible: false   // 新增／更新，弹窗visible状态
    }
  },
  mounted () {
    this.getDataList()
  },
  methods: {
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      this.$http.get(
        this.mixinViewModuleOptions.getDataListURL,
        {
          params: {
            page: this.mixinViewModuleOptions.getDataListIsPage ? this.page : null,
            limit: this.mixinViewModuleOptions.getDataListIsPage ? this.limit : null,
            ...this.dataForm
          }
        }
      ).then(({ data: res }) => {
        this.dataListLoading = false
        if (res.code !== 0) {
          this.dataList = []
          return this.$message.error(res.msg)
        }
        this.dataList = this.mixinViewModuleOptions.getDataListIsPage ? (res.data.list ? res.data.list : res.data.records) : res.data
      }).catch(() => {
        this.dataListLoading = false
      })
    },
    // 新增 / 修改
    addOrUpdateHandle (id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.dataForm.id = id
        this.$refs.addOrUpdate.init()
      })
    },
    // 删除
    deleteHandle (id) {
      if (this.mixinViewModuleOptions.deleteIsBatch && !id && this.dataListSelections.length <= 0) {
        return this.$message({
          message: '请选择删除项',
          type: 'warning',
          duration: 500
        })
      }
      this.$confirm('确定进行[删除]操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.delete(
          `${this.mixinViewModuleOptions.deleteURL}${this.mixinViewModuleOptions.deleteIsBatch ? '' : '/' + id}`,
          this.mixinViewModuleOptions.deleteIsBatch ? {
            'data': id ? [id] : this.dataListSelections.map(item => item[this.mixinViewModuleOptions.deleteIsBatchKey])
          } : {}
        ).then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: '操作成功',
            type: 'success',
            duration: 500,
            onClose: () => {
              this.getDataList()
            }
          })
        }).catch(() => {})
      }).catch(() => {})
    }
  },
  components: {
    TableTreeColumn,
    AddOrUpdate
  }
}
</script>
